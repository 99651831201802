// Packages
import * as Sentry from '@sentry/browser'

export const sendSentryError = ({error, context, tags } : {
  error: unknown,
  context: { name: string, data: any},
  tags: { key: string, values: string }
}): void => {
  typeof window?.Sentry?.captureException !== 'undefined' &&
  Sentry.withScope(function () {
    if (context?.name && context?.data) {
      Sentry.setContext(context.name, context.data)
    }

    if (tags?.key && tags?.values) {
      Sentry.setTag(tags.key, tags.values)
    }
    Sentry.captureException(error)
  })
}

export const sentrySetting = {
  eventsInfo: {
    logged: {
      name: 'logged',
      values: {
        hasSession: true,
        noSession: false
      }
    },
    typeUser: {
      name: 'typeUser',
      values: {
        businessAccount: 'B2B',
        signedCustomer: 'signedCustomer',
        guestCustomer: 'guestCustomer'
      }
    },
    function: 'function',
    components: {
      name: 'components',
      values: {
        SubscriptionAddress: 'SubscriptionAddress',
        SubscriptionBillingDate: 'SubscriptionBillingDate',
        SubscriptionPaymentMethod: 'SubscriptionPaymentMethod',
        ProfileContent: 'ProfileContent',
        ProfileSubscription: 'ProfileSubscription'
      }
    }
  }
}
